export const Location = () => (
  <svg
    width="13"
    height="17"
    viewBox="0 0 13 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.5 0C4.77672 0.00208886 3.1246 0.706393 1.90606 1.95842C0.687511 3.21044 0.00203992 4.90795 6.91533e-06 6.67857C-0.00205706 8.12553 0.457949 9.53323 1.30946 10.6857C1.30946 10.6857 1.48673 10.9255 1.51569 10.9601L6.5 17L11.4867 10.9571C11.5127 10.9249 11.6905 10.6857 11.6905 10.6857L11.6911 10.6839C12.5422 9.53191 13.002 8.12486 13 6.67857C12.998 4.90795 12.3125 3.21044 11.0939 1.95842C9.8754 0.706393 8.22328 0.00208886 6.5 0V0ZM6.5 9.10714C6.03252 9.10714 5.57553 8.96471 5.18684 8.69785C4.79814 8.431 4.49518 8.05171 4.31629 7.60795C4.13739 7.16418 4.09058 6.67588 4.18178 6.20478C4.27298 5.73368 4.4981 5.30095 4.82866 4.96131C5.15922 4.62167 5.58038 4.39037 6.03888 4.29666C6.49738 4.20296 6.97263 4.25105 7.40452 4.43486C7.83642 4.61868 8.20557 4.92995 8.46529 5.32933C8.72501 5.72871 8.86363 6.19825 8.86363 6.67857C8.86285 7.32242 8.61358 7.93967 8.17048 8.39494C7.72738 8.85021 7.12663 9.10634 6.5 9.10714V9.10714Z"
      fill="#737070"
    />
  </svg>
);
export const TimeIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 0C3.5888 0 0 3.5888 0 8C0 12.4112 3.5888 16 8 16C12.4112 16 16 12.4112 16 8C16 3.5888 12.4112 0 8 0ZM10.6344 11.7656L7.2 8.3312V3.2H8.8V7.6688L11.7656 10.6344L10.6344 11.7656V11.7656Z"
      fill="#737070"
    />
  </svg>
);
