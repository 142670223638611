import React from "react";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="d-flex footer justify-content-center align-items-center">
      <h5 className="mb-0"> {t("Powered by")} </h5>
      <h6 className="mb-0 ms-1">Sysma</h6>
    </div>
  );
};

export default Footer;
