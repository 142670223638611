import React, { useState, useEffect } from "react";
import Compressor from "compressorjs";
import UploadImageIcon from "../../images/uploadImg.png";
import { useTranslation } from "react-i18next";
const ImageUpload = ({
  imgSrc,
  setImgSrc,
  onBlurCapture,
  id,
  removePrevAssetsData,
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    const activeId = localStorage.getItem("activeAssetsId");
    // const imagesUrl = localStorage.getItem("images");
    const imageFileData = localStorage.getItem("images");
    if (
      imageFileData &&
      imageFileData.length &&
      imageFileData.length > 0 &&
      id === activeId
    ) {
      setImgSrc(JSON.parse(imageFileData));
    }
  }, []);

  const imageCaptureHandler = (e) => {
    let preImg = imgSrc;
    removePrevAssetsData();
    const fileArray = [...e.target.files];
    console.log(fileArray);
    localStorage.setItem("activeAssetsId", id);
    fileArray.forEach((fileData, i) => {
      new Compressor(fileData, {
        quality: 0.4,
        success(result) {
          var reader = new FileReader();
          reader.readAsDataURL(result);
          reader.addEventListener("load", (e) => {
            console.log(e.target.result);
            setImgSrc((pre) => [...pre, e.target.result]);
            preImg.push(e.target.result);
            localStorage.setItem("images", JSON.stringify(preImg));
          });
        },
        error(err) {
          console.log(err.message);
        },
      });
    });
  };

  const onRemoveHandler = (data, i) => {
    const removeImage = imgSrc.filter((img, index) => i !== index);
    setImgSrc(removeImage);
    localStorage.setItem("images", JSON.stringify(removeImage));
  };

  return (
    <>
      {imgSrc.length > 0 ? (
        <>
          <div className="container">
            <div style={{ maxWidth: "390px", margin: "auto" }}>
              <div className="d-flex flex-wrap">
                {imgSrc.map((img, i) => (
                  <div key={i} className="px-1 mx-auto">
                    <div className="upload-image mb-3 position-relative ">
                      <span
                        onClick={() => onRemoveHandler(img, i)}
                        className="remove-image"
                      >
                        <svg
                          width="30"
                          height="30"
                          viewBox="0 0 30 30"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M15 0C6.72923 0 0 6.72923 0 15C0 23.2708 6.72923 30 15 30C23.2708 30 30 23.2708 30 15C30 6.72923 23.2708 0 15 0ZM15 2.30769C22.0235 2.30769 27.6923 7.97654 27.6923 15C27.6923 22.0235 22.0235 27.6923 15 27.6923C7.97654 27.6923 2.30769 22.0235 2.30769 15C2.30769 7.97654 7.97654 2.30769 15 2.30769ZM10.6385 8.97692L8.97692 10.6385L13.3431 15L8.97923 19.3615L10.6408 21.0231L15 16.6581L19.3615 21.0196L21.0231 19.3615L16.6581 15L21.0196 10.6385L19.3615 8.97692L15 13.3431L10.6385 8.97923V8.97692Z"
                            fill="black"
                          />
                        </svg>
                      </span>
                      <img src={img} className="" alt="" />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      <div className="text-center d-flex px-2 justify-content-center align-items-center">
        <button className="default-btn d-flex flex-column align-items-center w-100  position-relative text-black py-4 my-3 ">
          <input
            id="icon-button-file"
            type="file"
            className="input-file"
            accept="image/*"
            multiple
            name="imgSrc"
            onBlurCapture={onBlurCapture}
            onChange={imageCaptureHandler}
          />
          <img
            style={{ width: "35px", height: "35px" }}
            src={UploadImageIcon}
            alt=""
          />
          <span className="_text-dark-graish">{t("Add More")}</span>
        </button>
      </div>
    </>
  );
};

export default ImageUpload;
