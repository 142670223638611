import React from "react";
import Transitions from "../../hoc/Transitions";
import UploadImageIcon from "../../images/uploadImg.png";

const FinalImageUpload = ({
  viewFromHandler,
  inspectionValue,
  setInspectionValue,
  onUploadFinalImage,
  prevStep,
  inspectionForm,
}) => {
  return (
    <Transitions>
      <form
        className="h-100 d-flex flex-column"
        onSubmit={(e) => viewFromHandler(e, inspectionForm.length + 2)}
      >
        <div className="flex-grow-1 d-flex flex-column overflow-hidden">
          <div className="w-100  py-2 _bg-primary ">
            <h5 className="m-0 px-3 w-100 assets-details-title text-center">
              Upload Final Image
            </h5>
          </div>
          {inspectionValue &&
          inspectionValue.asset_img &&
          inspectionValue.asset_img !== "" ? (
            <>
              <div className="inspection-image-upload overflow-auto mx-2 my-2 position-relative">
                <span
                  onClick={() => {
                    setInspectionValue((pre) => ({
                      ...pre,
                      asset_img: "",
                    }));
                    localStorage.setItem(
                      "inspectionData",
                      JSON.stringify({
                        ...inspectionValue,
                        asset_img: "",
                      })
                    );
                  }}
                  className="remove-image"
                >
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 0C6.72923 0 0 6.72923 0 15C0 23.2708 6.72923 30 15 30C23.2708 30 30 23.2708 30 15C30 6.72923 23.2708 0 15 0ZM15 2.30769C22.0235 2.30769 27.6923 7.97654 27.6923 15C27.6923 22.0235 22.0235 27.6923 15 27.6923C7.97654 27.6923 2.30769 22.0235 2.30769 15C2.30769 7.97654 7.97654 2.30769 15 2.30769ZM10.6385 8.97692L8.97692 10.6385L13.3431 15L8.97923 19.3615L10.6408 21.0231L15 16.6581L19.3615 21.0196L21.0231 19.3615L16.6581 15L21.0196 10.6385L19.3615 8.97692L15 13.3431L10.6385 8.97923V8.97692Z"
                      fill="black"
                    />
                  </svg>
                </span>
                <img className="w-100" src={inspectionValue.asset_img} alt="" />
              </div>
            </>
          ) : (
            <div className="px-2">
              <button className="default-btn   d-flex flex-column align-items-center w-100  position-relative text-black py-4 my-3 ">
                <input
                  id="icon-button-file"
                  type="file"
                  className="input-file"
                  accept="image/*"
                  required
                  onChange={(e) => onUploadFinalImage(e)}
                />
                <img
                  style={{ width: "35px", height: "35px" }}
                  src={UploadImageIcon}
                  alt=""
                />
                <span className="_text-dark-graish">Upload Image</span>
              </button>
            </div>
          )}
        </div>
        <div className="d-flex my-2 justify-content-center align-items-center">
          <div className="px-1 w-50">
            <button
              onClick={() => prevStep(inspectionForm.length + 1)}
              className="default-btn py-2 w-100 _bg-primary"
            >
              Previous
            </button>
          </div>
          <div className="px-1 w-50">
            <button
              type="submit"
              // onClick={() => viewFromHandler()}
              className="default-btn py-2 w-100 _bg-secondary"
            >
              View Form
            </button>
          </div>
        </div>
      </form>
    </Transitions>
  );
};

export default FinalImageUpload;
