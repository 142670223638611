import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import Transitions from "../../hoc/Transitions";
import NumberAuthentication from "./NumberAuthentication";

const InspectAuthentication = () => {
  const isAuth = useSelector((state) => state.Product.isAuth);
  if (!isAuth) {
    return (
      <>
        <Transitions>
          <NumberAuthentication />
        </Transitions>
      </>
    );
  } else {
    return <Redirect to="/" />;
  }
};

export default InspectAuthentication;
