import "./App.css";
import url from "./helpers/url-helper";
import { Route, Switch, useLocation } from "react-router-dom";
import AssetsDetails from "./components/assets/AssetsDetails";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import ScanQr from "./components/service/ScanQr";
import Header from "./components/common/Header";
import InspectionAssets from "./components/inspect/InspectionAssets";
import AssetsReport from "./components/report/AssetsReport";
import {
  RecaptchaVerifier,
  getAuth,
  signInWithPhoneNumber,
} from "firebase/auth";
import { AnimatePresence } from "framer-motion";
import Footer from "./components/common/Footer";
import { userDataAction } from "./redux/action/ProductAction";
import Verifiacation from "./components/auth/Verifiacation";
import ReportAuthentication from "./components/auth/ReportAuthentication";
import InspectAuthentication from "./components/auth/InspectAuthentication";
import UnAuthorized from "./components/common/UnAuthorized";
import InspectionAssetsList from "./components/inspect/InspectionAssetsList";
import InspectionsScheduleList from "./components/inspect/InspectionsScheduleList";
import QuestionProblem from "./components/report/QuestionProblem";
import { BrowserRouter } from "react-router-dom/cjs/react-router-dom";
function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  // useEffect(() => {
  //   const token = `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI1IiwianRpIjoiNGQ4ZGE1ZWJiODBkNjUwMzRkZGY4NWI5ODVmYzdiNDRjNTliODRhM2Q4N2NhNzdlNGZlYTQ2ZDU2ZDEwNGNjYzk4OTM1ZjQzNTE2OGZkZjUiLCJpYXQiOjE3MDk1NjEyNDMsIm5iZiI6MTcwOTU2MTI0MywiZXhwIjoyMTgyODYwNDQzLCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.WSjPHU6AokavncNVkc7SaLGBLe4QOKdecrEt41I2H-7rvb8kNBkbpMUDntSZfETj4RIHSoJrl-Oab8OkQ7Xwo9rUeH_hFsiHy2MtDcNcspn_tAtkIX-MclaYC8g8IerFfVNMTd7OUWGn-Mt3MrGixnS9mRvh_FGB7mwkY8I54ZukN0Nny_oCXPiyl4_Yhtd5L2xkLcoRxF6qCeAMSMkaBobDxA6zt26RnmqHyPtpZFnFOXep7uuI0WI0cIWxZLXbQzEtjSwCdRdu5-qNr9n3KmCwKtkjlGx8b6I7Jx_tSjRbAVA-3Tk5A1u4t-mEJU984vTK6NFih0N61pDqQU88DWNP64394HzL7LW43lc6buUJGhWJQousrfFXFaUp3vVCGmB5KYTiT0g-PjGYx7Cw-_2H5rDhkmee3h4MvArT7YbZ6Iu5pwGiesO9mIhMoi8V5p4w62KHuTewiKn47NBomdi76LQD1HDEyf3PzFgJKohlbHh03MIPIUKdQ3OznFMvX_QtScOrYqWpjc0WbW05OkV1Rud9rpfQL7CpqfRFPDWSKvse6SeeY0NTKIIVk96btYIVVvpU3SADTNFjqXtKkvGw-k4qgBXSEl_Nlst5U4JS0tSn0EO_ig4PcTHfvnKw4RWbhfEqcGGywbgOquik6QmGi-ixBJxQIuYK-5ZoSvI`;
  //   localStorage.setItem("token", token);
  //   if (localStorage.getItem("phone") && localStorage.getItem("token")) {
  //     const sysma_token = localStorage.getItem("token");
  //     const phone = localStorage.getItem("phone");
  //     const data = {
  //       sysma_token: sysma_token,
  //       phone: phone,
  //     };
  //     console.log(data);
  //     dispatch(userDataAction(data));
  //   }
  // }, []);
  useEffect(() => {
    // document.cookie = "username=John Doe";
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }, []);
  return (
    <>
      <Header />
      {/* <BrowserRouter basename="/report-problem/"> */}
      <AnimatePresence exitBeforeEnter initial={false}>
        <Switch location={location} key={location.pathname}>
          <Route exact path={("/")} component={ScanQr} />
          <Route
            exact
            path={("/inspector/auth")}
            component={InspectAuthentication}
          />
          <Route exact path={("/assets/:id")} component={AssetsDetails} />
          <Route
            exact
            path={("/inspect/:id")}
            component={InspectionAssets}
          />
          <Route exact path={("/report/:id")} component={AssetsReport} />
          <Route exact path={("/auth")} component={ReportAuthentication} />
          <Route exact path={("/verification")} component={Verifiacation} />
          <Route exact path={("/unauthorized")} component={UnAuthorized} />
          <Route
            exact
            path={("/inspection/assets/list")}
            component={InspectionAssetsList}
          />
          <Route
            exact
            path={("/inspection/schedule/assets/:id")}
            component={InspectionsScheduleList}
          />
          <Route
            exact
            path={("/inspection/assets/:id")}
            component={InspectionAssets}
          />
          {/* <Route
                exact
                path={("/report-problem/question-problem/:id/:quesId")} 
                component={QuestionProblem}
          /> */}
          <Route exact path={("/")} component={ScanQr} />
          {/* <Route component={FallbackComponent} /> */}
          {/* <Route
            exact
            path={(/report-problem/assets/inspection/:id/:questionId"
            component={Question}
          /> */}
        </Switch>
      </AnimatePresence>
      {/* </BrowserRouter> */}
      <Footer />
    </>
  );
}

export default App;
