import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
import Transitions from "../../hoc/Transitions";
import deviceDefaultImage from "../../images/deviceDefaultImage.jpg";
import {
  getDynamicUrlAction,
  inspactAuthorizationAction,
  inspectionProtectAction,
  isInspactRouteAction,
} from "../../redux/action/ProductAction";
import Loader from "../common/Loader";
import url from "../../helpers/url-helper";
const AssetsDetails = ({ history }) => {
  const { t } = useTranslation();
  const isAuth = useSelector((state) => state.Product.isAuth);
  let { id } = useParams();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.Product.isLoading);
  const user = useSelector((state) => state.Product.user);
  const assetsDetails = useSelector((state) => state.Product.assetsDetails);
  const userData = useSelector((state) => state.Product.userData);
  const assetsImage = useSelector((state) => state.Product.assetImage);
  const publicData = [];
  for (const key in assetsDetails?.public_data) {
    if (key === "image") {
      continue;
    }
    publicData.push(assetsDetails.public_data[key]);
  }
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const client = queryParams.get("client");
    localStorage.setItem("clientName", client);
    if (id !== "null") {
      localStorage.setItem("route_id", id);
    }
    if (id === "null") {
      id = localStorage.getItem("route_id");
    }
    dispatch(getDynamicUrlAction(client, id));
  }, []);
  useEffect(() => {
    if (isAuth) {
      // console.log("ISAUTH", userData)
      if (user && user.phone) {
        // var number = value.replace(/\D/g, "").slice(-10);
        const phone = user.phone;
        // const phone = `9687951446`;
        const showAlert = false;
        if (id === "null") {
          id = localStorage.getItem("route_id");
        }
        dispatch(
          inspactAuthorizationAction(
            phone,
            history,
            id,
            showAlert,
            t,
            assetsDetails.company_id
          )
        );
      }
    }
    if (isAuth && userData === null) {
      const phone = localStorage.getItem("phone");
      const assetDetails = localStorage.getItem("assetDetails");
      dispatch(inspectionProtectAction(phone, assetDetails.company_id));
    }
  }, [isAuth]);
  // useEffect(() => {

  // }, [])
  const inspactHandler = () => {
    dispatch(isInspactRouteAction(true));
    // console.log(isAuth && userData !== null, isAuth, userData, "isAuth && userData !== null")
    if (isAuth && userData !== null) {
      // history.push(`/inspection/schedule/assets/${id}`);
      history.push(`/inspection/assets/list`);
    }
    if (isAuth && userData === null) {
      localStorage.setItem("activeAssetsId", id);
      history.push(`/unauthorized`);
    }
    if (!isAuth) {
      history.push(`/inspector/auth`);
    }
  };

  return (
    <Transitions>
      <div className="detail-page w-100 flex-grow-1 d-flex overflow-auto">
        {loading ? (
          <div className="h-100 d-flex flex-grow-1 justify-content-center align-items-center">
            <Loader />
          </div>
        ) : (
          <>
            {assetsDetails !== null &&
            Object.keys(assetsDetails).length !== 0 ? (
              <div className=" w-100 h-100 d-flex flex-column align-items-center ">
                <div className="w-100  h-100 d-flex flex-column  assets-block my-2  max-w-620  ">
                  <div className="d-flex assets-heading-bar  py-2 _bg-primary align-items-center  justify-content-center ">
                    <h5 className="m-0 px-3  assets-details-title text-center">
                      {assetsDetails.name}
                    </h5>
                  </div>
                  <div className="mx-auto w-100 d-flex justify-content-center align-items-center">
                    <img width="70%" src={assetsImage} alt="image N/A" />
                  </div>
                  <div
                    className={`d-flex justify-content-center mt-1 align-items-center flex-column`}
                  >
                    <h4 className="fs-18 fw-700  _text-secondary">
                      {assetsDetails?.public_data.length !== 0 &&
                        assetsDetails?.public_data?.model_name?.value}
                    </h4>
                    <h6 className="_text-light fw-700">
                      {assetsDetails.asset_tag}
                    </h6>
                  </div>
                  <div className="d-flex flex-column flex-grow-1">
                    <div className="details-rows flex-grow-1 px-2 mt-3">
                      <div className="divider w-100  _bg-light-100"></div>
                      {publicData.map((pubData, i) => (
                        <>
                          <div
                            className="d-flex py-1 px-1 align-items-center"
                            key={i}
                          >
                            <span className="w-50 fs-15 fw-700">
                              {t(`${pubData.label}`)}
                            </span>
                            <span className="w-50 fs-15 ps-5 fw-700 _text-light ">
                              {pubData.value}
                            </span>
                          </div>
                          <div className="divider w-100  _bg-light-100"></div>
                        </>
                      ))}
                      {/* <div className="d-flex py-1 px-1 align-items-center">
                        <span className="w-50 fs-15 fw-700">
                          {t("Supplier Name")}
                        </span>
                        <span className="w-50 fs-15 ps-5 fw-700 _text-light ">
                          {assetsDetails?.public_data?.supplier_name?.value}
                        </span>
                      </div> */}
                      {/* <div className="divider w-100  _bg-light-100"></div> */}
                    </div>
                    <div className="d-flex mt-3 pb-3 justify-content-center align-items-center">
                      <div className="px-1 w-50">
                        <button
                          onClick={() =>
                            history.push(`/report/${id}`)
                          }
                          className="default-btn py-2 w-100 _bg-primary"
                        >
                          {t("Report Problem")}
                        </button>
                      </div>
                      {/* <div className="px-1 w-50">
                        <button
                          onClick={inspactHandler}
                          className="default-btn py-2 w-100 _bg-secondary"
                        >
                          {t("Inspect Now")}
                        </button>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            ) : assetsDetails === null ? (
              <div className="h-100 d-flex flex-grow-1 justify-content-center align-items-center">
                <h5> {t("Data N/A")} </h5>
              </div>
            ) : (
              <div className="h-100 d-flex flex-grow-1 justify-content-center align-items-center">
                <Loader />
              </div>
            )}
          </>
        )}
      </div>
    </Transitions>
  );
};

export default withRouter(AssetsDetails);
