import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import dummy from "./../assets/dummy.json";
import {
  getAssetsCauseAction,
  getAssetsDetailsAction,
  isInspactRouteAction,
  submitCausesAction,
} from "../../redux/action/ProductAction";
import { useTranslation } from "react-i18next";
import url from "../../helpers/url-helper";
export const useAssetsReport = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const initialErrors = {
    assets: "",
    comment: "",
    status: "",
    title: "",
    imgSrc: "",
  };
  let { id } = useParams();
  const isAuth = useSelector((state) => state.Product.isAuth);
  const assetsCause = useSelector((state) => state.Product.assetsCause);
  const loading = useSelector((state) => state.Product.causesSubmit?.loading);
  const phone = useSelector((state) => state.Product.user?.phone);
  const assetsName = useSelector(
    (state) => state.Product?.assetsDetails?.public_data?.category_name?.value
  );
  const [errors, setErrors] = useState(initialErrors);
  const [title, setTitle] = useState();
  const [comment, setComment] = useState();
  const [imgSrc, setImgSrc] = useState([]);
  const [files, setFiles] = useState([]);
  const [status, setStatus] = useState();
  const [assetsData, setAssetsData] = useState(null);
  const [problemQues, setProblemQues] = useState([]);
  const removeAssetsDataFromLocal = () => {
    localStorage.removeItem("comment");
    localStorage.removeItem("selectedQue");
    localStorage.removeItem("comment");
    localStorage.removeItem("images");
    localStorage.removeItem("files");
    localStorage.removeItem("status");
    localStorage.removeItem("title");
  };
  useEffect(() => {
    dispatch(isInspactRouteAction(false));
    dispatch(getAssetsCauseAction(id));
  }, []);
  useEffect(() => {
    // localStorage.setItem('route_id', id)
    if (!assetsName) {
      if (id === "null") {
        id = localStorage.getItem("route_id");
      }
      dispatch(getAssetsDetailsAction(id));
    }
  }, [assetsName]);
  useEffect(() => {
    const ques = JSON.parse(localStorage.getItem("selectedQue"));
    const activeId = localStorage.getItem("activeAssetsId");
    if (ques !== null && id === activeId) {
      setProblemQues(ques);
    } else {
      setProblemQues([]);
    }
  }, []);
  useEffect(() => {
    const activeId = localStorage.getItem("activeAssetsId");
    const saveTitle = localStorage.getItem("title");
    if (id === activeId) {
      setTitle(saveTitle);
    }
  }, []);

  const onStateChangeHandler = (e) => {
    setStatus(e.target.value);
    localStorage.setItem("activeAssetsId", id);
    localStorage.setItem("status", e.target.value);
  };

  // COMMENT CHANGE HANDLER
  const commentHandler = (e) => {
    removePrevAssetsData();
    setComment(e.target.value);
    localStorage.setItem("comment", e.target.value);
  };

  const removePrevAssetsData = () => {
    const activeId = localStorage.getItem("activeAssetsId");
    if (activeId !== id) {
      localStorage.setItem("activeAssetsId", id);
    }
  };

  // TITLE CHANGE HANDLER
  const titleHandler = (e) => {
    removePrevAssetsData();
    console.log(e.target.value);
    setTitle(e.target.value);
    localStorage.setItem("activeAssetsId", id);
    localStorage.setItem("title", e.target.value);
  };

  // SUBMIT HANDLER
  const submitCauseHandler = (e, value) => {
    if (value == "Bydirect") {
      e.preventDefault();
    }
    dispatch(isInspactRouteAction(false));
    const imageUpload = localStorage.getItem("uploadedFileIds");
    console.log("iimageUpload",imageUpload)
    // const assetsValue = assetsData.filter((obj) => obj.isChecked === true);
    // const assetsValue = JSON.parse(localStorage.getItem("selectedQue"))[0]
    const causeQues = problemQues;
    if (causeQues.length === 0) {
      alert(t("Please select at least one problem."));
    } else {
      if (isAuth) {
        let causes = [];
        causes.push(causeQues[0].id);
        const data = {
          asset_id: id,
          title: title,
          causes: causes,
          images: JSON.parse(imageUpload),
          description: comment,
          asset_working_status: status,
          phone: phone,
        };

        dispatch(submitCausesAction(data, removeAssetsDataFromLocal, history));
        localStorage.removeItem("uploadedFileIds");
      } else {
        history.push({
          pathname: ("/auth"),
          state: true,
        });
      }
    }
  };

  const onChangeHandler = (e, obj) => {
    removePrevAssetsData();
    const dummy = JSON.parse(localStorage.getItem("assetCause"));
    // localStorage.setItem("activeAssetsId", id);
    // const index = assetsData.indexOf(obj);
    // console.log("obj", obj, "==> index ", index, "==> assetsData", assetsData)
    // if (e.target.checked) {
    //   let new_array = assetsData.map((element, i) =>
    //     index === i ? { ...element, isChecked: true } : element
    //   );
    //   console.log("new_array", new_array);
    //   localStorage.setItem("selectedQue", JSON.stringify(new_array));
    //   setAssetsData(new_array);
    // }
    // if (!e.target.checked) {
    //   let new_array = assetsData.map((element, i) =>
    //     index === i ? { ...element, isChecked: false } : element
    //   );
    //   localStorage.setItem("selectedQue", JSON.stringify(new_array));
    //   setAssetsData(new_array);
    // }
    const new_array = [];
    if (e.target.checked) {
      dummy.map((assetData, i) => {
        if (assetData.isChecked) {
          assetData.isChecked = false;
        }
        if (assetData.id === obj.id) {
          assetData.isChecked = true;
          new_array.push(assetData);
        }
      });
    }

    if (new_array.length === 1) {
      localStorage.setItem("selectedQue", JSON.stringify(new_array));
      setProblemQues(new_array);
    }
  };

  useEffect(() => {
    if (assetsCause) {
      let assestsDataModified = [];
      assetsCause.forEach((obj) => {
        const newObj = { ...obj, isChecked: false };
        assestsDataModified.push(newObj);
      });
      setAssetsData(assestsDataModified);
    }
    const activeId = localStorage.getItem("activeAssetsId");
    const ques = JSON.parse(localStorage.getItem("selectedQue"));
    const statusValue = localStorage.getItem("status");
    if (ques && ques.length && ques.length > 0 && id === activeId) {
      setAssetsData(ques);
    }
    const commentsData = localStorage.getItem("comment");
    if (
      commentsData &&
      commentsData.length &&
      commentsData.length > 0 &&
      id === activeId
    ) {
      setComment(commentsData);
    }
    if (statusValue && id === activeId) {
      setStatus(statusValue);
    }
  }, [assetsCause]);

  function checkCauseSelected(dataId) {
    const activeAssetsId = localStorage.getItem("activeAssetsId");
    let causeIsSelected = JSON.parse(localStorage.getItem("selectedQue"));
    if (causeIsSelected === null && activeAssetsId !== id) {
      return false;
    } else {
      if (
        causeIsSelected !== null &&
        causeIsSelected[0]?.id === dataId &&
        activeAssetsId === id
      ) {
        if (problemQues.length > 0 && dataId === problemQues[0].id) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    }
  }
  return {
    setComment,
    comment,
    imgSrc,
    setImgSrc,
    files,
    setFiles,
    removeAssetsDataFromLocal,
    status,
    setStatus,
    title,
    setTitle,
    assetsData,
    setAssetsData,
    errors,
    setErrors,
    onStateChangeHandler,
    commentHandler,
    removePrevAssetsData,
    titleHandler,
    submitCauseHandler,
    onChangeHandler,
    checkCauseSelected,
    loading,
  };
};
