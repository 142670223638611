import React from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Logo from "../../images/logo.png";
import { logoutAction } from "../../redux/action/ProductAction";
const Header = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.Product.isAuth);
  const companyLogo = useSelector((state) => state.Product.companyLogo);
  const queryParams = new URLSearchParams(window.location.search)
  const clientName = useSelector((state) => state.Product.assetsDetails);
  const client = queryParams.get("client");
  const logOutHandler = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("phone");
    localStorage.removeItem("clientAuth");
    localStorage.removeItem("assetCause");
    dispatch(logoutAction());
    history.push('/')
  };

  return (
    <div className="header position-relative d-flex justify-content-center py-1 align-items-center">
      {companyLogo !== null && companyLogo!== undefined ? (
        <img
          onClick={() => history.push("/")}
          width="100px"
          src={companyLogo}
          alt=""
        />
      ) : clientName !== null && clientName.company_name !== undefined ? (
        <h1>{ clientName.company_name }</h1>
      ) : <h1>{ client }</h1>}
      {isAuth && (
        <>
          <div className="account-dropdown">
            <Dropdown bsPrefix="account">
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <svg
                  width="8"
                  height="33"
                  viewBox="0 0 8 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4 12.553C3.47471 12.553 2.95457 12.6551 2.46927 12.8534C1.98396 13.0518 1.54301 13.3425 1.17157 13.709C0.800139 14.0756 0.505501 14.5107 0.304482 14.9895C0.103463 15.4684 4.13633e-07 15.9817 4.13633e-07 16.5C4.13633e-07 17.0183 0.103463 17.5316 0.304482 18.0105C0.505501 18.4893 0.800139 18.9244 1.17157 19.291C1.54301 19.6575 1.98396 19.9482 2.46927 20.1466C2.95457 20.3449 3.47471 20.447 4 20.447C5.06087 20.4468 6.07819 20.0307 6.82816 19.2903C7.57814 18.55 7.99933 17.5459 7.99909 16.4991C7.99885 15.4523 7.57719 14.4484 6.82688 13.7084C6.07656 12.9684 5.05905 12.5527 3.99818 12.553H4ZM4 7.89045C4.52505 7.89021 5.04491 7.78793 5.5299 7.58945C6.01489 7.39096 6.45551 7.10016 6.82661 6.73365C7.19771 6.36714 7.49201 5.93209 7.69272 5.45334C7.89343 4.97459 7.9966 4.46152 7.99637 3.94343C7.99613 3.42534 7.89247 2.91236 7.69133 2.4338C7.49018 1.95523 7.19548 1.52044 6.82404 1.15426C6.45261 0.78808 6.01172 0.497675 5.52654 0.299626C5.04137 0.101578 4.52141 -0.00023509 3.99637 4.07602e-07C2.93598 0.000476032 1.91922 0.416588 1.16976 1.1568C0.420292 1.89701 -0.000481579 2.90068 4.13633e-07 3.94702C0.000482406 4.99336 0.42218 5.99665 1.17233 6.73619C1.92247 7.47572 2.93962 7.89092 4 7.89045V7.89045ZM4 25.106C2.93914 25.106 1.92172 25.5218 1.17157 26.262C0.421428 27.0022 4.13633e-07 28.0062 4.13633e-07 29.053C4.13633e-07 30.0998 0.421428 31.1037 1.17157 31.8439C1.92172 32.5842 2.93914 33 4 33C5.06087 33 6.07828 32.5842 6.82843 31.8439C7.57857 31.1037 8 30.0998 8 29.053C8 28.0062 7.57857 27.0022 6.82843 26.262C6.07828 25.5218 5.06087 25.106 4 25.106V25.106Z"
                    fill="black"
                  />
                </svg>
              </Dropdown.Toggle>
              <Dropdown.Menu className="p-1">
                <div
                  onClick={logOutHandler}
                  className="px-3 fs-16 fw-700 py-2 cursor-pointer"
                >
                  {t("Log out")}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </>
      )}
    </div>
  );
};

export default Header;
