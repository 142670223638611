import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAssetsScheduleAction } from "../../redux/action/ProductAction";
import moment from "moment";
import { useHistory } from "react-router-dom";
import url from "../../helpers/url-helper";
export const useInspection = () => {
  const history = useHistory();
  const [pendingScheduleList, setPendingSchedule] = useState([]);
  const assetsList = useSelector(
    (state) => state.Product.myAssetsListSchedule?.data
  );
  const loading = useSelector(
    (state) => state.Product.myAssetsListSchedule?.loading
  );
  const user_id = localStorage.getItem("user_id");
  const assetDetails = JSON.parse(localStorage.getItem("assetDetails"));
  const dispatch = useDispatch();
  useEffect(() => {
    if (assetsList && assetsList.length && assetsList.length > 0) {
      return;
    }
    dispatch(getAssetsScheduleAction(user_id, assetDetails.company_id, assetDetails.id));
  }, []);
  useEffect(() => {
    //===== GET EXPIRE DATE IS OVER ARRAY
    const current = moment(new Date());
    if (assetsList && assetsList.length && assetsList.length > 0) {
      const pending = assetsList.filter((obj) => {
        const compared = moment(obj.schedule_expire_date);
        const diff = current.diff(compared, "minutes");
        return diff < 0;
      });
      setPendingSchedule(pending);
    }
  }, [assetsList]);
  const openInpectionRoute = (id) => {
    history.push(`/inspection/assets/${id}`);
  };
  return { pendingScheduleList, loading, openInpectionRoute };
};
