import url from "../../helpers/url-helper";
import {
  getAssetsCauseApi, getAssetsDetailsApi, getAssetsScheduleApi, getCompanyLogoApi, inspactAuthorizationApi, inspectionFormSubmitApi,
  submitCausesApi
} from "../apis/productApis";
import { dynamicBaseUrl } from "../helper";
export const ASSETS_DETAILS = `ASSETS_DETAILS`;
export const ASSETS_DETAILS_START = `ASSETS_DETAILS_START`;
export const ASSETS_DETAILS_ERROR = `ASSETS_DETAILS_ERROR`;

export const ASSETS_LIST = `ASSETS_LIST`;
export const ASSETS_LIST_START = `ASSETS_LIST_START`;
export const ASSETS_LIST_ERROR = `ASSETS_LIST_ERROR`;

export const ASSETS_CAUSE_START = `ASSETS_CAUSE_START`;
export const ASSETS_CAUSE_ERROR = `ASSETS_CAUSE_ERROR`;
export const ASSETS_CAUSE = `ASSETS_CAUSE`;
export const SAVE_USER = `SAVE_USER`;
export const INSPACT_AUTHORIZATION_START = `INSPACT_AUTHORIZATION_START`;
export const REMOVE_USER = `REMOVE_USER`;
export const INSPACT_AUTHORIZATION_SUCCESS = `INSPACT_AUTHORIZATION_SUCCESS`;

export const INSPACT_AUTHORIZATION_ERROR = `INSPACT_AUTHORIZATION_ERROR`;

export const INSPACT_ROUTE = `INSPACT_ROUTE`;

export const GET_ASSETS_SCHEDULE_START = `GET_ASSETS_SCHEDULE_START`;
export const GET_ASSETS_SCHEDULE_SUCCESS = `GET_ASSETS_SCHEDULE_SUCCESS`;
export const GET_ASSETS_SCHEDULE_ERROR = `GET_ASSETS_SCHEDULE_ERROR`;
export const SUBMIT_INSPECTION_FORM_START = `SUBMIT_INSPECTION_FORM_START`;
export const SUBMIT_INSPECTION_FORM_ERROR = `SUBMIT_INSPECTION_FORM_ERROR`;
export const USER_DATA = `USER_DATA`;
export const SCHEDULE_LIST = `SCHEDULE_LIST`;
export const SUBMIT_INSPECTION_FORM_SUCCESS = `SUBMIT_INSPECTION_FORM_SUCCESS`;

export const SUBMIT_CAUSES = `SUBMIT_CAUSES`;
export const TOKEN_BASE_URL = `TOKEN_BASE_URL`;
export const TOKEN_BASE_URL_START = `TOKEN_BASE_URL_START`;
export const TOKEN_BASE_URL_ERROR = `TOKEN_BASE_URL_ERROR`;
export const COMPANY_LOGO = `COMPANY_LOGO`;
export const COMPANY_LOGO_ERROR = `COMPANY_LOGO_ERROR`;
export const ASSETS_IMAGE = `ASSETS_IMAGE`;
export const ASSETS_IMAGE_ERROR = `ASSETS_IMAGE_ERROR`;
//==================== GET ASSETS DISPATCH ================
const getAssetsDetailsSuccess = (data) => ({
  type: ASSETS_DETAILS,
  data,
  isLoading: false,
});
const getAssetsDetailsStart = () => ({
  type: ASSETS_DETAILS_START,
  isLoading: true,
});
const getAssetsDetailsError = () => ({
  type: ASSETS_DETAILS_ERROR,
  isLoading: false,
  data: null,
});

//==================== CHECK THE ROUTE ====================
export const isInspactRouteAction = (data) => ({
  type: INSPACT_ROUTE,
  route: data,
});
//==================== GET TOKEN AND BASE URL DISPATCH ================
const tokenAndBaseUrlActionStart = () => ({
  type: TOKEN_BASE_URL_START,
  loading: true
});
const tokenAndBaseUrlAction = (data) => ({
  type: TOKEN_BASE_URL,
  data: data,
  loading: false
});
const tokenAndBaseUrlActionError = () => ({
  type: TOKEN_BASE_URL_ERROR,
  data: null,
  loading: false
});

//=================== GET DYNAMIC URL & TOKEN ACTION ============

export const getDynamicUrlAction = (client, id) => async (dispatch) => {
  tokenAndBaseUrlActionStart()
  try {
    const resp = await dynamicBaseUrl(client);
    if(resp.data.status === 'success'){
      localStorage.setItem('clientAuth', JSON.stringify(resp))
      dispatch(tokenAndBaseUrlAction(resp));
      // dispatch(getCompanyLogoAction());
      dispatch(getAssetsDetailsAction(id));
    }else{
      dispatch(tokenAndBaseUrlActionError());
    }
  } catch (error) {
    dispatch(tokenAndBaseUrlActionError());
  }
}

//=================== GET COMPANY LOGO ACTION ============
const getCompanyLogoStart = (data) => ({
  type: COMPANY_LOGO,
  data: data,
})
const getCompanyLogoError = () => ({
  type: COMPANY_LOGO_ERROR,
  data: null,
});

//=================== GET COMPANY LOGO ACTION ============
export const getCompanyLogoAction = () => async (dispatch, getState) => {
  const state = getState();
  try {
    const response = await getCompanyLogoApi(state.Product.clientAuth.data.payload)
    dispatch(getCompanyLogoStart(response.results[0].image))
  } catch (error) {
    dispatch(getCompanyLogoError())
  }
}

//=================== GET ASSETS IMAGE ACTION ============
const getAssetsImageStart = (data) => ({
  type: ASSETS_IMAGE,
  data: data,
})
const getAssetsImageError = () => ({
  type: ASSETS_IMAGE_ERROR,
  data: null,
});

//=================== GET ASSETS DETAILS ACTION ============
export const getAssetsDetailsAction = (id) => async (dispatch, getState) => {
  const state = getState();
  dispatch(getAssetsDetailsStart());
  try {
    const response = await getAssetsDetailsApi(id, state.Product.clientAuth.data.payload);
    if (response.status === "success") {
      dispatch(getAssetsDetailsSuccess(response.payload));
      localStorage.setItem('assetDetails', JSON.stringify(response.payload))
      if (response.payload.asset_image !== null) {
        dispatch(getAssetsImageStart(response.payload.asset_image));
      } else {
        dispatch(getAssetsImageError());
      }

      if (response.payload.company_image !== null) {
        dispatch(getCompanyLogoStart(response.payload.company_image));
      } else {
        dispatch(getCompanyLogoError());
      }
      
    } else {
      dispatch(getAssetsDetailsError());
    }
  } catch (error) {
    dispatch(getAssetsImageError());
    dispatch(getCompanyLogoError());
  }
};

//==================== GET ASSETS CAUSE DISPATCH ================
const getAssetsCauseStart = () => ({
  type: ASSETS_CAUSE_START,
  isLoading: true,
});
const getAssetsCauseError = () => ({
  type: ASSETS_CAUSE_ERROR,
  isLoading: false,
});
const getAssetsCauseSuccess = (data) => ({
  type: ASSETS_CAUSE,
  data: data,
  isLoading: false,
});
//================== GET ASSETS CAUSE ACTION ================================

export const getAssetsCauseAction = (id) => async (dispatch,getState) => {
  const state = getState();
  dispatch(getAssetsCauseStart());
  try {
    const response = await getAssetsCauseApi(id, state.Product.clientAuth.data.payload);
    if (response.status === "success") {
      dispatch(getAssetsCauseSuccess(response.payload));
    } else {
      dispatch(getAssetsCauseError());
    }
  } catch (error) {
    dispatch(getAssetsCauseError());
  }
};

const userDataSuccess = (user) => ({
  type: SAVE_USER,
  data: user,
  isAuth: true,
});

//==================== USER DATA ACTION ====================
export const userDataAction = (user) => async (dispatch) => {
  dispatch(userDataSuccess(user));
};
const logoutSuccess = () => ({
  type: REMOVE_USER,
});
export const logoutAction = () => (dispatch) => {
  dispatch(logoutSuccess());
};

const inspactAuthorizationStart = () => ({
  type: INSPACT_AUTHORIZATION_START,
  isLoading: true,
});
const inspactAuthorizationSuccess = (data) => ({
  type: INSPACT_AUTHORIZATION_SUCCESS,
  data: data,
  loading: false,
});
const inspactAuthorizationError = () => ({
  type: INSPACT_AUTHORIZATION_ERROR,
  loading: false,
});
export const inspactAuthorizationAction = (
  phone,
  history,
  id,
  showAlert,
  t,
  company_id
) => async (dispatch, getState) => {
  const state = getState()
  dispatch(inspactAuthorizationStart());
  if (id === 'null') {
    id = localStorage.getItem('route_id')
  }
  try {
    const response = await inspactAuthorizationApi(phone, company_id, state.Product.clientAuth.data.payload);
    localStorage.setItem("user_id", response.data.id);
    if (response.api_status == 1) {
      dispatch(inspactAuthorizationSuccess(response.data));
      if (showAlert) {
        // history.push(url(`/inspection/schedule/assets/${id}`);
        history.push(`/inspection/assets/list`);
      }
    } else {
      dispatch(inspactAuthorizationError());
      const client = localStorage.getItem('clientName')
      if (showAlert) {
        alert(t(response.api_message));
        history.push(`assets/${id}?client=${client}`);
      }
    }
  } catch (error) {
    dispatch(inspactAuthorizationError());
  }
};

const getScheduleList = (data) => ({
  type: SCHEDULE_LIST,
  data: data,
});
export const getAssetsScheduleAction = (user_id, company_id, asset_id) => async (dispatch, getState) => {
  let data = {
    data: null,
    loading: true,
  };
  const state = getState();
  const clientAuth = JSON.parse(localStorage.getItem('clientAuth'))
  dispatch(getScheduleList(data));
  try {
    const response = await getAssetsScheduleApi(user_id, company_id, asset_id, clientAuth === null ? state.Product.clientAuth.data.payload : clientAuth.data.payload);
    if (response.api_status === 1) {
      data = {
        data: response.totalAssetScheduledata,
        loading: false,
      };
      // if (response['total count'] > 0 || response['total'] > 0) {
      if (response.totalAssetScheduledata.length > 0) {
        dispatch(getScheduleList(data));
      } else {
        alert(response.api_message);
      }
    } else {
      data = {
        data: null,
        loading: false,
      };
      dispatch(getScheduleList(data));
    }
  } catch (error) {
    data = {
      data: null,
      loading: false,
    };
    dispatch(getScheduleList(data));
    // dispatch(getAssetsScheduleError());
  }
};
const inspectionFormSubmitActionStart = () => ({
  type: SUBMIT_INSPECTION_FORM_START,
});
const inspectionFormSubmitError = () => ({
  type: SUBMIT_INSPECTION_FORM_ERROR,
});
const inspectionFormSubmitActionSuccess = () => ({
  type: SUBMIT_INSPECTION_FORM_SUCCESS,
});
export const inspectionFormSubmitAction = (data, history, setLoading) => async (
  dispatch, getState
) => {
  dispatch(inspectionFormSubmitActionStart());
  const state = getState();
  try {
    const response = await inspectionFormSubmitApi(data, state.Product.clientAuth.data.payload);
    if (response.api_status === 1) {
      alert(response.api_message);
      dispatch(inspectionFormSubmitActionSuccess());
      history.push(`/inspection/assets/list`);
    } else {
      dispatch(inspectionFormSubmitError());
    }
  } catch (error) {
    dispatch(inspectionFormSubmitError());
  }
};

const inspectionActionDispatch = (data) => ({
  type: USER_DATA,
  data: data,
});
export const inspectionProtectAction = (phone, company_id) => async (dispatch, getState) => {
  let data = {
    data: null,
    loading: true,
  };
  const state = getState()
  const clientAuth = JSON.parse(localStorage.getItem('clientAuth'))
  dispatch(inspectionActionDispatch(data));
  try {
    const response = await inspactAuthorizationApi(phone, company_id, clientAuth === null ? state.Product.clientAuth.data.payload : clientAuth.data.payload);
    if (response.api_status == 1) {
      data = {
        data: response.data,
        loading: false,
      };
      localStorage.setItem("user_id", response.data.id);
      dispatch(inspectionActionDispatch(data));
    } else {
      data = {
        data: null,
        loading: false,
      };
      dispatch(inspectionActionDispatch(data));
    }
  } catch (error) {
    data = {
      data: null,
      loading: false,
    };
    dispatch(inspectionActionDispatch(data));
  }
};

// SUBMIT CAUSES ACTION
const submitAction = (payload) => ({
  type: SUBMIT_CAUSES,
  data: payload,
});
export const submitCausesAction = (
  data,
  removeAssetsDataFromLocal,
  history
) => async (dispatch, getState) => {
  let payload = {
    loading: true,
    data: null,
  };
  dispatch(submitAction(payload));
  const state = getState();
  try {
    const response = await submitCausesApi(data, state.Product.clientAuth.data.payload);
    console.log(response);
    if (response.status == "success") {
      console.log(response);
      payload = {
        data: response.data,
        loading: false,
      };
      dispatch(submitAction(payload));
      removeAssetsDataFromLocal();
      alert(response.messages);
      history.push(`/`);
    } else {
      payload = {
        data: null,
        loading: false,
      };
      dispatch(submitAction(payload));
    }
  } catch (error) {
    payload = {
      data: null,
      loading: false,
    };
    dispatch(submitAction(data));
  }
};
