import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import InspactionProtect from "../../hoc/InspactionProtect";
import { Location, TimeIcon } from "../common/Icon";
import Loader from "../common/Loader";
import { useInspection } from "../hooks/useInspection";
import url from "../../helpers/url-helper";

const InspectionAssetsList = () => {
  const history = useHistory();
  const { pendingScheduleList, loading, openInpectionRoute } = useInspection();
  const { t } = useTranslation();
  return (
    <InspactionProtect>
      <div className="detail-page pt-1  flex-grow-1 d-flex overflow-hidden">
        <div className="w-100 d-flex flex-column overflow-hidden">
          {loading &&
          pendingScheduleList &&
          pendingScheduleList.length === 0 ? (
            <>
              <div className="h-100 d-flex flex-column justify-content-center align-items-center">
                <h6>No assets available for inpection</h6>
                <div className="text-center py-3">
                  <button
                    onClick={() => history.push(`/`)}
                    className="default-btn  _bg-primary border-0 text-white py-2 px-4"
                  >
                    Go To Home
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="d-flex w-100  py-2 _bg-primary ">
                <h5 className="m-0 px-3 w-100 assets-details-title text-center">
                  Select Schedule
                </h5>
              </div>
              <div className="overflow-auto flex-grow-1">
                {loading ? (
                  <div className="h-100 d-flex flex-grow-1 justify-content-center align-items-center">
                    <Loader />
                  </div>
                ) : (
                  <div className="px-2 py-2">
                    {pendingScheduleList &&
                      pendingScheduleList.length > 0 &&
                      pendingScheduleList.map((obj) => (
                        <React.Fragment key={obj.audit_schdule_id}>
                          <div
                            onClick={() => openInpectionRoute(obj.asset_tag_id)}
                            className="list-box d-flex my-3 mx-auto"
                          >
                            <div className="list-box-img-wrapper">
                              <img
                                src={obj.asset_image || `https://staging.sysmatech.com/zydus/public/uploads/assets/${obj.image}`}
                                alt=""
                              />
                            </div>
                            <div className="list-content">
                              <h4 className="fs-16 fw-bold _text-secondary">
                                {obj.audit_name}
                              </h4>
                              <h5 className="_text-light mb-1 fs-16 fw-bold">
                                {obj.asset_tag}
                              </h5>
                              <div className="d-flex align-items-center">
                                <Location />
                                <span className=" ms-1 _text-light fs-16 ">
                                  {obj.location}
                                </span>
                              </div>
                              <div className="d-flex align-items-center justify-content-between _text-light fs-16">
                                <div className="d-flex align-items-center">
                                  <TimeIcon />
                                  <span className="ms-1">
                                    {moment(obj.due_date).format("DD-MM-YYYY")}
                                  </span>
                                </div>
                                <div className="pending-status">
                                  {obj.status_label}
                                </div>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      ))}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </InspactionProtect>
  );
};

export default InspectionAssetsList;
