import React from "react";
import { Spinner } from "react-bootstrap";
const SpinnerLoader = () => {
  return (
    <Spinner variant="white" size="sm" animation="grow" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  );
};

export default SpinnerLoader;
