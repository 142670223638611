import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InspactionProtect from "../../hoc/InspactionProtect";
import { Location, TimeIcon } from "../common/Icon";
import Loader from "../common/Loader";
import { useInspection } from "../hooks/useInspection";
import { useParams, useHistory } from "react-router-dom";
import url from "../../helpers/url-helper";
const InspectionsScheduleList = () => {
  const { id } = useParams();
  const history = useHistory();
  const { pendingScheduleList, loading, openInpectionRoute } = useInspection();
  const { t } = useTranslation();

  const filterList = pendingScheduleList.filter(
    (data) => data.asset_tag_id == id
  );

  return (
    <InspactionProtect>
      <div className="detail-page pt-1  flex-grow-1 d-flex overflow-hidden">
        <div className="w-100 d-flex flex-column overflow-hidden">
          {!loading && filterList && filterList.length === 0 ? (
            <>
              <div className="h-100 d-flex flex-column justify-content-center align-items-center">
                <h6>No assets available for inpection</h6>
                <div className="text-center py-3">
                  <button
                    onClick={() => history.push(`/inspection/assets/list`)}
                    className="default-btn  _bg-primary border-0 text-white py-2 px-4"
                  >
                    Go Back
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              {loading ? (
                <>
                  <div className="h-100 d-flex flex-grow-1 justify-content-center align-items-center">
                    <Loader />
                  </div>
                </>
              ) : (
                <>
                  <div className="d-flex w-100  py-2 _bg-primary ">
                    <h5 className="m-0 px-3 w-100 assets-details-title text-center">
                      Select Schedule
                    </h5>
                  </div>
                  <div className="overflow-auto flex-grow-1">
                    <div className="px-2 py-2">
                      {pendingScheduleList &&
                        pendingScheduleList.length > 0 &&
                        pendingScheduleList
                          .filter((data) => data.asset_tag_id == id)
                          .map((obj) => (
                            <>
                              <div
                                onClick={() => openInpectionRoute(obj.asset_tag_id)}
                                className="list-box d-flex my-3 mx-auto"
                              >
                                <div className="list-box-img-wrapper">
                                  <img
                                    src={`https://staging.sysmatech.com/zydus/public/uploads/assets/${obj.image}`}
                                    alt=""
                                  />
                                </div>
                                <div className="list-content">
                                  <h4 className="fs-16 fw-bold _text-secondary">
                                    {" "}
                                    {obj.name}{" "}
                                  </h4>
                                  <h5 className="_text-light mb-1 fs-16 fw-bold">
                                    {obj.asset_tag}
                                  </h5>
                                  <div className="d-flex align-items-center">
                                    <Location />
                                    <span className=" ms-1 _text-light fs-16 ">
                                      Location - {obj.location}
                                    </span>
                                  </div>
                                  <div className="d-flex align-items-center">
                                    <TimeIcon />
                                    <span className=" ms-1 _text-light fs-16 ">
                                      <span className="pending-status">
                                        {" "}
                                        {obj.audit_status}{" "}
                                      </span>
                                      {moment(obj.schedule_expire_date).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </InspactionProtect>
  );
};

export default InspectionsScheduleList;
