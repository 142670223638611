import React, { useState } from "react";
import {
  RecaptchaVerifier,
  getAuth,
  signInWithPhoneNumber,
} from "firebase/auth";
import { withRouter } from "react-router-dom";
import { authenticate } from "../../firebase-config";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import SpinnerLoader from "../common/SpinnerLoader";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import url from "../../helpers/url-helper";
const NumberAuthentication = ({ history }) => {
  const { t } = useTranslation();
  const isAuth = useSelector((state) => state.Product.isAuth);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const auth = getAuth();
  const [phone, setPhone] = useState();
  const recaptchSubmit = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
        callback: () => {},
      },
      auth
    );
  };

  const requestOptHandler = () => {
    setLoading(true);
    if (!window.recaptchaVerifier) {
      recaptchSubmit();
    }
    const appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, phone, appVerifier)
      .then((res) => {
        setLoading(false);
        window.confirmationResult = res;
        if (res && res.verificationId) {
          history.push({
            pathname: ("/verification"),
          });
        }
      })
      .catch(() => {
        setError(true);
        setErrorMessage("Invalid Phone Number");
        setLoading(false);
      });
  };

  return (
    <>
      <div className="flex-grow-1 mt-2 d-flex flex-column">
        <h3 className="fs-16 text-center _bg-primary text-white py-2 fw-700 text-black">
          {t("Please Enter Your Mobile No.")}
        </h3>
        <div className="flex-grow-1 d-flex pt-4  flex-column">
          <div className="d-flex mx-3 flex-column mt-3 px-2">
            <PhoneInput
              placeholder={t("Enter phone number")}
              value={phone}
              className="phone-input"
              defaultCountry="IN"
              onChange={(e) => {
                setPhone(e);
                setError(false);
              }}
            />
            {errorMessage && error && (
              <span className="error"> {errorMessage} </span>
            )}
          </div>
          <div className="text-center px-3 mt-4">
            <button
              onClick={requestOptHandler}
              disabled={loading}
              className="default-btn _bg-primary w-100 px-5 py-2"
            >
              {loading ? (
                <>
                  <SpinnerLoader /> {t("Loading")}...
                </>
              ) : (
                t("Generate OTP")
              )}
            </button>
          </div>
        </div>
      </div>
      <div id="sign-in-button"></div>
    </>
  );
};

export default withRouter(NumberAuthentication);
