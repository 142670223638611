import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Transitions from "../../hoc/Transitions";
import Loader from "../common/Loader";
import SpinnerLoader from "../common/SpinnerLoader";
import { useAssetsReport } from "../hooks/useAssetsReport";
import ImageUpload from "./ImageUpload";
import dummy from "./../assets/dummy.json";
import { LeftArrow, RightArrow } from "./Arrow";
import { useState } from "react";
import {ReactComponent as AlertIcon} from "./Alert.svg";
const AssetsReport = () => {
  const { t } = useTranslation();
  const {
    comment,
    imgSrc,
    setImgSrc,
    files,
    setFiles,
    status,
    title,
    errors,
    onStateChangeHandler,
    commentHandler,
    removePrevAssetsData,
    titleHandler,
    submitCauseHandler,
    onChangeHandler,
    checkCauseSelected,
    loading,
  } = useAssetsReport();
  const { id } = useParams();
  localStorage.setItem("activeAssetsId", id);
  const assetsName = useSelector(
    (state) => state.Product?.assetsDetails?.public_data?.category_name?.value
  );
  const assetCause = useSelector((state) => state.Product?.assetsCause);
  const isLoading = useSelector((state) => state.Product.isLoading);
  const [currentId, setCurrentId] = useState(0);
  const [previousId, setPreviousId] = useState(null);
  const [assetState, setAssetState] = useState(assetCause);
  function manipulateCauseArray(data) {
    const filteredData = data.filter(item => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].id === item.parent_id) {
          return false;
        }
          if (item.parent_id === 0) {
              return false
          }
      }
      return true;
    });
    
    const filteredDataArr = data.filter(item => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].id === item.parent_id) {
          return true;
        }
          if (item.parent_id === 0) {
              return true
          }
      }
      return false;
    });
    
    const noArr = filteredData.map(mp => {
      mp.parent_id = 0
      mp.has_child = 0
      return mp
    })
    
    const fullArr = [...noArr, ...filteredDataArr]
    return fullArr
  }
  useEffect(() => {
    setAssetState(assetCause);
    assetCause.map(mp => {
      mp['isChecked'] = false
      return mp
    })
    localStorage.setItem('assetCause', JSON.stringify(manipulateCauseArray(assetCause)))
  }, [assetCause]);

  const nextQuestion = (dataId) => {
    setCurrentId(dataId);
    const res = assetCause.filter((fil) => fil.parent_id === dataId);
    setAssetState(res);
  };
  const previousQuestion = () => {
    const prevIdQuestion = assetCause.filter((fil) => fil.parent_id === previousId);
    const currIdQuestion = assetCause.filter((fil) => fil.id === prevIdQuestion[0].parent_id);
    setAssetState(prevIdQuestion);
    setCurrentId(prevIdQuestion[0].parent_id)
    setPreviousId(currIdQuestion[0].parent_id)
  }
  return (
    <>
      <Transitions>
        <div className="assets-cause-section mt-2 flex-grow-1 overflow-auto d-flex flex-column">
          {isLoading ? (
            <>
              <div className="h-100 d-flex flex-grow-1 justify-content-center align-items-center">
                <Loader />
              </div>
            </>
          ) : (
            <>
              {assetCause && assetCause.length === 0 ? (
                <>
                  <div className="d-flex h-100 justify-content-center align-items-center">
                    <AlertIcon style={{width: '50px'}}/>
                    <p> {t("Causes N/A")} </p>
                  </div>
                </>
              ) : (
                <div className=" max-w-620 d-flex flex-column overflow-hidden flex-grow-1 mx-auto w-100">
                  <h3 className="fs-18 px-3 text-center _bg-primary mb-0 text-white py-2 fw-700 _text-dark-graish">
                    {assetsName}
                  </h3>
                  <h3 className="fs-18 text-center _text-dark-graish _bg-light-100 mb-0  py-2 fw-700 ">
                    {(currentId !== 0) && <span onClick={()=>previousQuestion()}>
                      <LeftArrow/>
                    </span>}
                    {t("Please select the problem")}
                  </h3>
                  <form
                    className="d-flex flex-grow-1 overflow-auto  flex-column"
                    onSubmit={(e) => submitCauseHandler(e, "Bydirect")}
                  >
                    <div className="d-flex flex-grow-1 overflow-auto  flex-column">
                      {assetState &&
                        assetState
                          .filter((fil) => (fil.parent_id === currentId))
                          .map((data, i) => (
                            <div key={i} className="d-flex flex-column">
                              {data.has_child === 0 ? (
                                <div className="d-flex px-2 py-2 question-wrapper-el ">
                                  <label
                                    className="fs-15 w-75 flex-grow-1 _text-dark-graish"
                                    htmlFor={data.id}
                                  >
                                    {data.cause_question}
                                  </label>
                                  <input
                                    className="question-checkbox"
                                    type="radio"
                                    id={data.id}
                                    onChange={(e) =>{
                                      onChangeHandler(e, data, i)
                                    }}
                                    name="assets"
                                    // checked={causeIsSelected[0]?.id === data.id ? true : data.isChecked}
                                    checked={checkCauseSelected(data.id)}
                                    value={data.id}
                                  />
                                </div>
                              ) : (
                                <div
                                  className="d-flex px-2 py-2 question-wrapper-el "
                                  onClick={() => {
                                    nextQuestion(data.id);
                                    setPreviousId(currentId);
                                  }}
                                >
                                  <label
                                    className="fs-15 w-75 flex-grow-1 _text-dark-graish"
                                    htmlFor={data.id}
                                  >
                                    {data.cause_question}
                                  </label>
                                  <RightArrow
                                  />
                                </div>
                              )}
                            </div>
                          ))}
                      {errors && errors.assets && (
                        <>
                          <p className="error px-2"> {errors.assets} </p>{" "}
                        </>
                      )}
                      <div className="d-flex px-2 mb-2 mt-3">
                        <p className="mb-0"> {t("Status")}: </p>
                        <div className="d-flex ms-4 me-3 align-items-center">
                          <input
                            type="radio"
                            id="Breakdown"
                            name="status"
                            value={0}
                            checked={+status === 0}
                            className="status-radio"
                            onChange={onStateChangeHandler}
                          />
                          <label htmlFor="Breakdown"> {t("Breakdown")} </label>
                        </div>
                        <div className="d-flex align-items-center">
                          <input
                            type="radio"
                            id="Operational"
                            onChange={onStateChangeHandler}
                            name="status"
                            className="status-radio"
                            checked={+status === 1}
                            value={1}
                          />
                          <label htmlFor="Operational">
                            {t("Operational")}
                          </label>
                        </div>
                      </div>
                      {errors && errors.status && (
                        <>
                          <p className="error px-2"> {errors.status} </p>{" "}
                        </>
                      )}
                      <div className="d-flex px-2 mb-2 mt-3 flex-column">
                        <label
                          className=" fs-16 _text-dark-graish mb-1"
                          htmlFor="title"
                        >
                          {t("Title")}
                        </label>
                        <input
                          id="title"
                          name="title"
                          rows="4"
                          cols="50"
                          required
                          value={title}
                          onChange={(e) => titleHandler(e)}
                          className="p-2"
                        />
                      </div>
                      {errors && errors.title && (
                        <>
                          <p className="error px-2"> {errors.title} </p>{" "}
                        </>
                      )}
                      <div className="d-flex px-2 mb-2 mt-3 flex-column">
                        <label
                          className=" fs-16 _text-dark-graish mb-1"
                          htmlFor="Description"
                        >
                          {t("Description")}
                        </label>
                        <textarea
                          id="Description"
                          name="comment"
                          rows="4"
                          cols="50"
                          value={comment}
                          onChange={(e) => commentHandler(e)}
                          className="p-2"
                        ></textarea>
                      </div>
                      {errors && errors.comment && (
                        <>
                          <p className="error px-2"> {errors.comment} </p>
                        </>
                      )}
                      <ImageUpload
                        imgSrc={imgSrc}
                        setImgSrc={setImgSrc}
                        files={files}
                        setFiles={setFiles}
                        id={id}
                        removePrevAssetsData={removePrevAssetsData}
                      />
                      {errors && errors.imgSrc && (
                        <>
                          <p className="error px-2"> {errors.imgSrc} </p>{" "}
                        </>
                      )}
                    </div>
                    <div className="text-center">
                      <button
                        type="submit"
                        disabled={loading}
                        className="default-btn w-50 py-2 mt-3 mb-1 bg-dark"
                      >
                        {loading ? (
                          <>
                            <SpinnerLoader /> {t("Loading")}...
                          </>
                        ) : (
                          <> {t("Submit")}</>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </>
          )}
        </div>
      </Transitions>
    </>
  );
};

export default AssetsReport;
