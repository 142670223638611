import { motion } from "framer-motion";

const Transitions = ({ children, slide = 0, slideUp = 100 }) => {
  return (
    <motion.div
      className="flex-grow-1 d-flex flex-column overflow-hidden"
      initial={{ opacity: 0, x: slide, y: slideUp }}
      animate={{ opacity: 1, x: 0, y: 0 }}
      exit={{ opacity: 0, x: slide, y: slideUp }}
    >
      {children}
    </motion.div>
  );
};
export default Transitions;
