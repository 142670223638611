// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAbJSC4N7mNWK-zKYt0TI6-lHz9fpezerQ",
  authDomain: "adani-fse.firebaseapp.com",
  projectId: "adani-fse",
  storageBucket: "adani-fse.appspot.com",
  messagingSenderId: "613849827777",
  appId: "1:613849827777:web:b8345a078449bf7f7c4740",
  measurementId: "G-KRNM7291XZ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const authentication = getAuth(app);
