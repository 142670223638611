import React from "react";
import UploadImageIcon from "../../images/uploadImg.png";

const InspectionImageUpload = ({
  setInspectionValue,
  onImageUpload,
  obj,
  inspectionValue,
}) => {
  return (
    <>
      <div className="text-center mt-2 d-flex  justify-content-center align-items-center">
        {inspectionValue[obj.audit_camera_photos_key] &&
        inspectionValue[obj.audit_camera_photos_key] !== "" ? (
          <>
            <div className="inspection-image-upload position-relative">
              <span
                onClick={() => {
                  setInspectionValue((pre) => ({
                    ...pre,
                    [obj.audit_camera_photos_key]: "",
                  }));
                  localStorage.setItem(
                    "inspectionData",
                    JSON.stringify({
                      ...inspectionValue,
                      [obj.audit_camera_photos_key]: "",
                    })
                  );
                }}
                className="remove-image"
              >
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15 0C6.72923 0 0 6.72923 0 15C0 23.2708 6.72923 30 15 30C23.2708 30 30 23.2708 30 15C30 6.72923 23.2708 0 15 0ZM15 2.30769C22.0235 2.30769 27.6923 7.97654 27.6923 15C27.6923 22.0235 22.0235 27.6923 15 27.6923C7.97654 27.6923 2.30769 22.0235 2.30769 15C2.30769 7.97654 7.97654 2.30769 15 2.30769ZM10.6385 8.97692L8.97692 10.6385L13.3431 15L8.97923 19.3615L10.6408 21.0231L15 16.6581L19.3615 21.0196L21.0231 19.3615L16.6581 15L21.0196 10.6385L19.3615 8.97692L15 13.3431L10.6385 8.97923V8.97692Z"
                    fill="black"
                  />
                </svg>
              </span>
              <img
                className="w-100"
                src={inspectionValue[obj.audit_camera_photos_key]}
                alt=""
              />
            </div>
          </>
        ) : (
          <>
            <button className="default-btn d-flex flex-column align-items-center w-100  position-relative text-black py-4 my-3 ">
              <input
                id="icon-button-file"
                type="file"
                required
                className="input-file"
                accept="image/*"
                onChange={(e) => onImageUpload(obj, e)}
              />
              <img
                style={{ width: "35px", height: "35px" }}
                src={UploadImageIcon}
                alt=""
              />
              <span className="_text-dark-graish">Upload Image</span>
            </button>
          </>
        )}
      </div>
    </>
  );
};

export default InspectionImageUpload;
