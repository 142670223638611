import React from "react";

const InspectionComment = ({ onDropdownCommentHandler, obj, value }) => {
  return (
    <div className="d-flex  mb-2 mt-3 flex-column">
      <label className=" fs-16 _text-dark-graish mb-1" htmlFor="w3review">
        Comment
      </label>
      <textarea
        id="w3review"
        name="w3review"
        rows="4"
        cols="50"
        required
        placeholder="Enter you comment here"
        value={value}
        onChange={(e) => onDropdownCommentHandler(e, obj)}
        className="p-2"
      ></textarea>
    </div>
  );
};

export default InspectionComment;
