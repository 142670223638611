import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SpinnerLoader from "../common/SpinnerLoader";

const ViewForm = ({
  inspectionForm,
  inspectionValue,
  prevStep,
  locationHandler,
  submitInspectionFormHandler,
}) => {
  const loading = useSelector((state) => state.Product.scheduleSubmitLoading);
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function(position) {
      const lati = position.coords.latitude;
      const long = position.coords.longitude;
      locationHandler(lati, long);
    });
  }, []);

  return (
    <div>
      {inspectionForm.map((data, i) => (
        <div className=" view-form-box mt-2 mb-3 mx-2">
          <div key={i}>
            <div className="w-100 br-6 py-2 _bg-primary">
              <h5 className="m-0 px-3 w-100 assets-details-title ">
                {i + 1}: {data.param_disp_name}?
              </h5>
            </div>
            <div className="form-value"> {inspectionValue[data.id]} </div>
            {inspectionValue[data.audit_comment_key] &&
              inspectionValue[data.audit_comment_key].length > 0 && (
                <>
                  <div className="mt-3 fw-bold">Comment:-</div>
                  <div className=" form-value">
                    {inspectionValue[data.audit_comment_key]}
                  </div>
                </>
              )}
            {inspectionValue[data.audit_camera_photos_key] &&
              inspectionValue[data.audit_camera_photos_key].length > 0 && (
                <>
                  <div className="mt-3 fw-bold">Image</div>
                  <div className="fw-bold mt-2 text-center">
                    <img
                      className="w-75"
                      src={inspectionValue[data.audit_camera_photos_key]}
                      alt=""
                    />
                  </div>
                </>
              )}
          </div>
        </div>
      ))}
      {inspectionValue.asset_img && inspectionValue.asset_img.length > 0 && (
        <>
          <div className=" view-form-box mt-2 mb-3 mx-2">
            <div className="w-100 br-6 py-2 _bg-primary">
              <h5 className="m-0 px-3 w-100 assets-details-title text-center">
                Final asset image
              </h5>
            </div>
            <div className="mt-2 text-center">
              <img className="w-75" src={inspectionValue.asset_img} alt="" />
            </div>
          </div>
        </>
      )}

      <div className="d-flex my-4 justify-content-center align-items-center">
        <div className="px-1 w-50">
          <button
            onClick={() => prevStep(2)}
            // disabled={i === 0}
            className="default-btn py-2 w-100 _bg-primary"
          >
            Edit
          </button>
        </div>
        <div className="px-1 w-50">
          <button
            type="submit"
            disabled={loading}
            onClick={() => submitInspectionFormHandler()}
            className="default-btn py-2 w-100 _bg-secondary"
          >
            {loading ? (
              <>
                <SpinnerLoader /> Loading...
              </>
            ) : (
              <>Submit</>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewForm;
