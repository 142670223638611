import React from "react";
import InspectionComment from "./InspectionComment";
import InspectionImageUpload from "./InspectionImageUpload";

const NumberComp = ({
  onNumberChangeHandler,
  obj,
  inspectionValue,
  onDropdownCommentHandler,
  onImageUpload,
  setInspectionValue,
}) => {
  const validation = JSON.parse(obj.validation);
  console.log(validation);

  return (
    <>
      <input
        onChange={(e) => onNumberChangeHandler(e, obj)}
        type="number"
        required
        value={inspectionValue[obj.id]}
        placeholder="Enter the number"
        className="w-100 p-2"
      />
      {Number(inspectionValue[obj.id]) < Number(obj.benchmark_range_high) &&
      Number(inspectionValue[obj.id]) > Number(obj.benchmark_range_low) ? (
        <>
          {validation.textfield_aacept_photos_validation === "Required" ? (
            <>
              <InspectionImageUpload
                inspectionValue={inspectionValue}
                onImageUpload={onImageUpload}
                obj={obj}
                setInspectionValue={setInspectionValue}
              />
            </>
          ) : (
            ""
          )}
          {validation.textfield_accept_comment_validation === "Required" ? (
            <InspectionComment
              onDropdownCommentHandler={onDropdownCommentHandler}
              obj={obj}
              value={inspectionValue[obj.audit_comment_key]}
            />
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}

      {Number(inspectionValue[obj.id]) > Number(obj.benchmark_range_high) ||
      (Number(inspectionValue[obj.id]) < Number(obj.benchmark_range_low) &&
        inspectionValue[obj.id] !== "") ? (
        <>
          {validation.textfield_reject_photos_validation === "Required" ? (
            <InspectionImageUpload
              inspectionValue={inspectionValue}
              obj={obj}
              onImageUpload={onImageUpload}
              setInspectionValue={setInspectionValue}
            />
          ) : (
            ""
          )}
          {validation.textfield_reject_comment_validation === "Required" ? (
            <InspectionComment
              onDropdownCommentHandler={onDropdownCommentHandler}
              obj={obj}
              value={inspectionValue[obj.audit_comment_key]}
            />
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default NumberComp;
