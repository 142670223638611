import React from "react";
import Transitions from "../../hoc/Transitions";
import { useInspectionAssets } from "../hooks/useInspectionAssets";
import NumberComp from "./NumberComp";
import DropdownComp from "./DropdownComp";
import FinalImageUpload from "./FinalImageUpload";
import ViewForm from "./ViewForm";
import InspactionProtect from "../../hoc/InspactionProtect";
import TextComp from "./TextComp";
import DateComp from "./DateComp";
const InspectionAssets = () => {
  const {
    inspectionForm,
    nextStep,
    prevStep,
    step,
    onNumberChangeHandler,
    onTextChangeHandler,
    onDateChangeHandler,
    onDropdownChangeHandler,
    setDropdownValidation,
    inspectionValue,
    onDropdownCommentHandler,
    onImageUpload,
    setInspectionValue,
    viewFromHandler,
    onUploadFinalImage,
    isPhotoRequiredError,
    locationHandler,
    submitInspectionFormHandler,
  } = useInspectionAssets();
  return (
    <>
      <Transitions>
        <InspactionProtect>
          <div className="flex-grow-1 inspection-assets d-flex flex-column overflow-auto">
            {inspectionForm &&
              inspectionForm.length &&
              inspectionForm.length > 0 &&
              inspectionForm.map((obj, i) => (
                <>
                  {Number(step) === i + 1 && (
                    <form
                      className="h-100 d-flex flex-grow-1 flex-column"
                      key={i}
                      onSubmit={(e) => nextStep(e, i + 1, obj)}
                    >
                      <Transitions>
                        <div className="flex-grow-1 overflow-auto">
                          <div className="w-100  py-2 _bg-primary">
                            <h5 className="m-0 px-3 w-100 assets-details-title text-center">
                              {i + 1}/{inspectionForm.length}
                            </h5>
                          </div>
                          <span className="px-2 fw-bold pt-3 d-flex fs-16 _text-dark-graish">
                            {obj.param_disp_name}?
                          </span>
                          <h6 className="w-100 px-2 py-2">
                            {obj.param_type === "Number" && (
                              <>
                                <NumberComp
                                  onNumberChangeHandler={onNumberChangeHandler}
                                  obj={obj}
                                  onDropdownCommentHandler={
                                    onDropdownCommentHandler
                                  }
                                  inspectionValue={inspectionValue}
                                  i={i}
                                  onImageUpload={onImageUpload}
                                  setInspectionValue={setInspectionValue}
                                />
                              </>
                            )}
                            {obj.param_type === "Text" && (
                              <>
                                <TextComp
                                  onTextChangeHandler={onTextChangeHandler}
                                  obj={obj}
                                  inspectionValue={inspectionValue}
                                  i={i}
                                />
                              </>
                            )}
                            {obj.param_type === "Date" && (
                              <>
                                <DateComp
                                  onDateChangeHandler={onDateChangeHandler}
                                  obj={obj}
                                  inspectionValue={inspectionValue}
                                  i={i}
                                />
                              </>
                            )}
                            {obj.param_type === "Drop-down" && (
                              <>
                                <DropdownComp
                                  obj={obj}
                                  onDropdownChangeHandler={
                                    onDropdownChangeHandler
                                  }
                                  setDropdownValidation={setDropdownValidation}
                                  inspectionValue={inspectionValue}
                                  onDropdownCommentHandler={
                                    onDropdownCommentHandler
                                  }
                                  onImageUpload={onImageUpload}
                                  setInspectionValue={setInspectionValue}
                                  isPhotoRequiredError={isPhotoRequiredError}
                                />
                              </>
                            )}
                          </h6>
                        </div>
                        <div className="d-flex my-2 justify-content-center align-items-center">
                          <div className="px-1 w-50">
                            <button
                              onClick={() => prevStep(i + 1)}
                              disabled={i === 0}
                              className="default-btn py-2 w-100 _bg-primary"
                            >
                              Previous
                            </button>
                          </div>
                          <div className="px-1 w-50">
                            <button
                              type="submit"
                              // onClick={() => nextStep(i + 1, obj)}
                              className="default-btn py-2 w-100 _bg-secondary"
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      </Transitions>
                    </form>
                  )}
                </>
              ))}
            {/* FINAL IMAGE UPLOAD */}
            {inspectionForm &&
              inspectionForm.length &&
              inspectionForm.length > 0 &&
              inspectionForm.length + 1 == step && (
                <>
                  <FinalImageUpload
                    viewFromHandler={viewFromHandler}
                    prevStep={prevStep}
                    inspectionValue={inspectionValue}
                    setInspectionValue={setInspectionValue}
                    onUploadFinalImage={onUploadFinalImage}
                    inspectionForm={inspectionForm}
                  />
                </>
              )}
            {inspectionForm &&
              inspectionForm.length &&
              inspectionForm.length > 0 &&
              inspectionForm.length + 2 == step && (
                <>
                  <ViewForm
                    inspectionForm={inspectionForm}
                    inspectionValue={inspectionValue}
                    prevStep={prevStep}
                    locationHandler={locationHandler}
                    submitInspectionFormHandler={submitInspectionFormHandler}
                  />
                </>
              )}
          </div>
        </InspactionProtect>
      </Transitions>
    </>
  );
};

export default InspectionAssets;
