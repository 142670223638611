import React from "react";
import { useEffect } from "react";
import Transitions from "../../hoc/Transitions";
import UploadImageIcon from "../../images/uploadImg.png";
import InspectionComment from "./InspectionComment";
import InspectionImageUpload from "./InspectionImageUpload";

const DropdownComp = ({
  obj,
  onDropdownChangeHandler,
  setDropdownValidation,
  inspectionValue,
  onDropdownCommentHandler,
  onImageUpload,
  setInspectionValue,
  isPhotoRequiredError,
}) => {
  const options = obj.dropdown_values.split(",");
  useEffect(() => {
    if (obj) {
      setDropdownValidation(JSON.parse(obj.validation));
    }
  }, [obj]);
  const validation = JSON.parse(obj.validation);
  return (
    <>
      <select
        onChange={(e) => onDropdownChangeHandler(e, obj)}
        className="w-100 p-2"
        required
        value={inspectionValue[obj.id]}
      >
        <option value="" disabled selected hidden>
          Select suitable value
        </option>
        {options.map((value) => (
          <>
            <option value={value}>{value}</option>
          </>
        ))}
      </select>

      {validation.map((item) => (
        <>
          {/* DROPDOWN VALUE IS ACCEPT */}
          {item.name === inspectionValue[obj.id] &&
          obj.dropdown_accept_value === inspectionValue[obj.id] ? (
            <>
              {/* IF COMMENT IS NEEDED */}
              {item.validation.dropdown_values_accept_comment_validation ===
              "Required" ? (
                <>
                  <Transitions>
                    <InspectionComment
                      value={inspectionValue[obj.audit_comment_key]}
                      obj={obj}
                      inspectionValue={inspectionValue}
                      onDropdownCommentHandler={onDropdownCommentHandler}
                    />
                  </Transitions>
                </>
              ) : (
                <></>
              )}
              {/* IF PHOTO IS NEEDED */}
              {item.validation.dropdown_values_aacept_photos_validation ===
              "Required" ? (
                <>
                  <Transitions>
                    <InspectionImageUpload
                      obj={obj}
                      setInspectionValue={setInspectionValue}
                      inspectionValue={inspectionValue}
                      onImageUpload={onImageUpload}
                      isPhotoRequiredError={isPhotoRequiredError}
                    />
                  </Transitions>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            ""
          )}

          {/* DROPDOWN VALUE IS REJECTED  */}
          {item.name === inspectionValue[obj.id] &&
          obj.dropdown_reject_value === inspectionValue[obj.id] ? (
            <>
              {/* IF COMMENT IS NEEDED */}
              {item.validation.dropdown_values_reject_comment_validation ===
              "Required" ? (
                <>
                  <Transitions>
                    <InspectionComment
                      value={inspectionValue[obj.audit_comment_key]}
                      obj={obj}
                      inspectionValue={inspectionValue}
                      onDropdownCommentHandler={onDropdownCommentHandler}
                    />
                  </Transitions>
                </>
              ) : (
                <></>
              )}
              {/* IF PHOTO IS NEEDED */}
              {item.validation.dropdown_values_reject_photos_validation ===
              "Required" ? (
                <>
                  <Transitions>
                    <InspectionImageUpload
                      obj={obj}
                      inspectionValue={inspectionValue}
                      onImageUpload={onImageUpload}
                      setInspectionValue={setInspectionValue}
                      isPhotoRequiredError={isPhotoRequiredError}
                    />
                  </Transitions>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            ""
          )}
        </>
      ))}
    </>
  );
};

export default DropdownComp;
