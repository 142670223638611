import { forEach } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Compressor from "compressorjs";

import {
  getAssetsScheduleAction,
  inspectionFormSubmitAction,
} from "../../redux/action/ProductAction";
import { useTranslation } from "react-i18next";

export const useInspectionAssets = () => {
  const history = useHistory();
  const [isPhotoRequiredError, setPhotoRequiredError] = useState(false);
  const [step, setStep] = useState(1);
  const [inspectionValue, setInspectionValue] = useState({});
  const [dropdownValidation, setDropdownValidation] = useState();
  const [assetDetail, setAssetDetail] = useState(null);
  const [inspectionForm, setInspectionForm] = useState(null);
  const { id } = useParams();
  const activeInspectionId = localStorage.getItem("activeInspectionId");
  const { t } = useTranslation();
  const saveDataInLocalStorage = (key, value) => {
    localStorage.setItem(
      "inspectionData",
      JSON.stringify({
        ...inspectionValue,
        [key]: value,
      })
    );
  };
  const assetsList = useSelector(
    (state) => state.Product.myAssetsListSchedule?.data
  );
  const user_id = localStorage.getItem("user_id");
  const dispatch = useDispatch();
  useEffect(() => {
    const assetDetails = JSON.parse(localStorage.getItem("assetDetails"));
    dispatch(getAssetsScheduleAction(user_id, assetDetails.company_id, assetDetails.id));
  }, []);

  useEffect(() => {
    //===== GET EXPIRE DATE IS OVER ARRAY
    const current = moment(new Date());
    if (assetsList && assetsList.length && assetsList.length > 0) {
      const pending = assetsList.filter((obj) => {
        const compared = moment(obj.schedule_expire_date);
        const diff = current.diff(compared, "minutes");
        return diff < 0;
      });
      const detail = pending.filter((obj) => obj.asset_tag_id == Number(id));
      setAssetDetail(detail[0]);
      // assetsList.forEach((element) => {
      //   // console.log(element);
      //   const param = JSON.parse(element.audit_params_values);
      //   param.forEach((value) => {
      //     // console.log(value);
      //     // console.log(value.dropdown_accept_value);
      //     // console.log(value.dropdown_reject_value);
      //     const validationValue = JSON.parse(value.validation);
      //     // console.log(validationValue);
      //     if (value.param_type === "Drop-down") {
      //       validationValue.forEach((element) => {
      //         // console.log(element.validation);
      //         console.log(
      //           element.validation.dropdown_values_aacept_photos_validation,
      //           "dropdown"
      //         );
      //         console.log(
      //           element.validation.dropdown_values_accept_comment_validation,
      //           "dropdown"
      //         );
      //         console.log(
      //           element.validation.dropdown_values_reject_comment_validation,
      //           "dropdown"
      //         );
      //         console.log(
      //           element.validation.dropdown_values_reject_photos_validation,
      //           "dropdown"
      //         );
      //       });
      //     } else {
      //       console.log(
      //         validationValue.textfield_aacept_photos_validation,
      //         "number"
      //       );
      //       console.log(
      //         validationValue.textfield_accept_comment_validation,
      //         "number"
      //       );
      //       console.log(
      //         validationValue.textfield_reject_comment_validation,
      //         "number"
      //       );
      //       console.log(
      //         validationValue.textfield_reject_photos_validation,
      //         "number"
      //       );
      //     }
      //   });
      // });
    }
  }, [assetsList]);

  useEffect(() => {
    if (assetDetail) {
      setInspectionForm(JSON.parse(assetDetail.audit_params_values));
    }
  }, [assetDetail]);

  const nextStep = (e, value, obj) => {
    e.preventDefault();
    localStorage.setItem("step", value + 1);
    setStep(value + 1);
    const validation = JSON.parse(obj.validation);
    validationHandler(obj, value);
  };
  //=== VALIDATION HANDLER ON NEXT

  const validationHandler = (obj, value) => {
    const validation = JSON.parse(obj.validation);
    if (obj.param_type === "Drop-down") {
      const getValidationFilter = validation.filter(
        (item) => inspectionValue[obj.id] === item.name
      );
      const validationObj = getValidationFilter[0];
      if (inspectionValue[obj.id] === obj.dropdown_accept_value) {
        if (
          validationObj.validation.dropdown_values_aacept_photos_validation !==
          "Required"
        ) {
          delete inspectionValue[obj.audit_camera_photos_key];
        }
        if (
          validationObj.validation.dropdown_values_accept_comment_validation !==
          "Required"
        ) {
          delete inspectionValue[obj.audit_comment_key];
        }
      }
      if (inspectionValue[obj.id] === obj.dropdown_reject_value) {
        if (
          validationObj.validation.dropdown_values_reject_photos_validation !==
          "Required"
        ) {
          delete inspectionValue[obj.audit_camera_photos_key];
        }
        if (
          validationObj.validation.dropdown_values_reject_comment_validation !==
          "Required"
        ) {
          delete inspectionValue[obj.audit_comment_key];
        }
      }
    }
    if (obj.param_type === "Number") {
      // IF VALUE IS ACCEPT IN NUMBER INPUT
      if (
        Number(inspectionValue[obj.id]) < Number(obj.benchmark_range_high) &&
        Number(inspectionValue[obj.id]) > Number(obj.benchmark_range_low) &&
        validation.textfield_aacept_photos_validation !== "Required"
      ) {
        delete inspectionValue[obj.audit_camera_photos_key];
      }
      if (
        Number(inspectionValue[obj.id]) < Number(obj.benchmark_range_high) &&
        Number(inspectionValue[obj.id]) > Number(obj.benchmark_range_low) &&
        validation.textfield_accept_comment_validation !== "Required"
      ) {
        delete inspectionValue[obj.audit_comment_key];
      }
      // IF VALUE IS REJECT IN NUMBER INPUT
      if (
        Number(inspectionValue[obj.id]) > Number(obj.benchmark_range_high) ||
        (Number(inspectionValue[obj.id]) < Number(obj.benchmark_range_low) &&
          validation.textfield_reject_photos_validation !== "Required")
      ) {
        delete inspectionValue[obj.audit_camera_photos_key];
      }
      if (
        Number(inspectionValue[obj.id]) > Number(obj.benchmark_range_high) ||
        (Number(inspectionValue[obj.id]) < Number(obj.benchmark_range_low) &&
          validation.textfield_reject_comment_validation !== "Required")
      ) {
        delete inspectionValue[obj.audit_comment_key];
      }
    }
  };
  const prevStep = (value) => {
    if (value === 1) {
    } else {
      localStorage.setItem("step", value - 1);
      setStep(value - 1);
    }
  };

  useEffect(() => {
    // FETCH ACTIVE STEP FROM LOCAL STORAGE
    const activeStep = localStorage.getItem("step");
    const inspectionData = localStorage.getItem("inspectionData");
    if (activeStep === null || activeStep === undefined || activeStep === 'undefined') {
      localStorage.setItem("step", step);
    }
    // CHECK THE INSPECTION ID ALONG WITH LOCALSTORAGE DATA
    if (id === activeInspectionId) {
      setStep(activeStep);
      if (inspectionData) {
        setInspectionValue(JSON.parse(inspectionData));
      } else {
        inspectionForm &&
          inspectionForm.length &&
          inspectionForm.forEach((data) => {
            setInspectionValue((pre) => ({
              ...pre,
              [data.id]: "",
              [data.audit_comment_key]: "",
              [data.audit_camera_photos_key]: "",
            }));
          });
      }
    } else {
      setStep(1);
      inspectionForm &&
        inspectionForm.length &&
        inspectionForm.forEach((data) => {
          setInspectionValue((pre) => ({
            ...pre,
            [data.id]: "",
            [data.audit_comment_key]: "",
            [data.audit_camera_photos_key]: "",
          }));
        });
      localStorage.setItem("activeInspectionId", id);
      localStorage.removeItem("inspectionData");
    }
  }, []);
  //=================  NUMBER ONCHANGE HANDLER
  const onNumberChangeHandler = (e, obj) => {
    const value = e.target.value;
    // console.log(value);
    setInspectionValue((pre) => ({
      ...pre,
      [obj.id]: value,
    }));
    saveDataInLocalStorage(obj.id, value);
    // console.log(obj);
    // console.log(value < Number(obj.benchmark_range_high));
    // console.log(value > Number(obj.benchmark_range_low));
    if (
      value < Number(obj.benchmark_range_high) &&
      value > Number(obj.benchmark_range_low)
    ) {
      // console.log("accept value");
    }
  };
  //=================  TEXT ONCHANGE HANDLER
  const onTextChangeHandler = (e, obj) => {
    const value = e.target.value;
    setInspectionValue((pre) => ({
      ...pre,
      [obj.id]: value,
    }));
    saveDataInLocalStorage(obj.id, value);
  };
  //=================  DATE ONCHANGE HANDLER
  const onDateChangeHandler = (e, obj) => {
    const value = e.target.value;
    setInspectionValue((pre) => ({
      ...pre,
      [obj.id]: value,
    }));
    saveDataInLocalStorage(obj.id, value);
  };
  //================= DROPDONW ONCHANGE HANDLER
  const onDropdownChangeHandler = (e, obj) => {
    const value = e.target.value;
    setInspectionValue((pre) => ({
      ...pre,
      [obj.id]: value,
    }));
    saveDataInLocalStorage(obj.id, value);
  };

  const onDropdownCommentHandler = (e, obj) => {
    const value = e.target.value;
    setInspectionValue((pre) => ({
      ...pre,
      [obj.audit_comment_key]: value,
    }));
    saveDataInLocalStorage(obj.audit_comment_key, value);
  };

  const onImageUpload = (obj, event) => {
    const result = event.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(result);
    new Compressor(result, {
      quality: 0.4,
      success(image) {
        var reader = new FileReader();
        reader.readAsDataURL(image);
        reader.addEventListener("load", (e) => {
          setInspectionValue((pre) => ({
            ...pre,
            [obj.audit_camera_photos_key]: e.target.result,
          }));
          saveDataInLocalStorage(obj.audit_camera_photos_key, e.target.result);
        });
      },
    });
  };
  const viewFromHandler = (e, value) => {
    e.preventDefault();
    localStorage.setItem("step", value);
    setStep(value);
  };
  const onUploadFinalImage = (event) => {
    const result = event.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(result);
    reader.addEventListener("load", (e) => {
      setInspectionValue((pre) => ({
        ...pre,
        asset_img: e.target.result,
      }));
      saveDataInLocalStorage("asset_img", e.target.result);
    });
  };
  const locationHandler = (lati, long) => {
    const lat_long = lati + "," + long;
    setInspectionValue((pre) => ({
      ...pre,
      lat_long: lat_long,
    }));
  };
  const submitInspectionFormHandler = () => {
    if (inspectionValue.lat_long) {
      const data = {
        ...inspectionValue,
        user_id: `${user_id}`,
        audit_schedule_id: assetDetail.audit_schdule_id,
        audit_status: "completed",
      };

      dispatch(inspectionFormSubmitAction(data, history));
    } else {
      alert(t("Please turn on you system location!"));
    }
  };
  return {
    assetDetail,
    inspectionForm,
    step,
    setStep,
    nextStep,
    prevStep,
    onNumberChangeHandler,
    onTextChangeHandler,
    onDateChangeHandler,
    onDropdownChangeHandler,
    setDropdownValidation,
    inspectionValue,
    onDropdownCommentHandler,
    activeInspectionId,
    dropdownValidation,
    id,
    onImageUpload,
    setInspectionValue,
    viewFromHandler,
    onUploadFinalImage,
    isPhotoRequiredError,
    locationHandler,
    submitInspectionFormHandler,
  };
};
