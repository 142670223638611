import {
  ASSETS_DETAILS_ERROR,
  ASSETS_DETAILS_START,
  ASSETS_DETAILS,
  ASSETS_CAUSE,
  ASSETS_CAUSE_START,
  ASSETS_CAUSE_ERROR,
  SAVE_USER,
  REMOVE_USER,
  INSPACT_AUTHORIZATION_SUCCESS,
  INSPACT_AUTHORIZATION_START,
  INSPACT_AUTHORIZATION_ERROR,
  INSPACT_ROUTE,
  GET_ASSETS_SCHEDULE_SUCCESS,
  GET_ASSETS_SCHEDULE_START,
  GET_ASSETS_SCHEDULE_ERROR,
  SUBMIT_INSPECTION_FORM_START,
  SUBMIT_INSPECTION_FORM_ERROR,
  USER_DATA,
  SCHEDULE_LIST,
  SUBMIT_INSPECTION_FORM_SUCCESS,
  SUBMIT_CAUSES,
  TOKEN_BASE_URL,
  TOKEN_BASE_URL_START,
  COMPANY_LOGO,
  COMPANY_LOGO_ERROR,
  ASSETS_IMAGE,
  ASSETS_IMAGE_ERROR,
  TOKEN_BASE_URL_ERROR
} from "../action/ProductAction";

// import { MOVIES_LIST, CHARACTERS_DATA } from "../action/ProductAction";
const initialState = {
  isLoading: false,
  assetsDetails: {},
  assetsCause: [],
  isAuth: false,
  user: null,
  userData: null,
  isInspactRoute: false,
  myAssetsListSchedule: null,
  scheduleSubmitLoading: null,
  causesSubmit: null,
  clientAuth: {},
  companyLogo: null,
  assetImage: null
};

export default function productReducer(state = initialState, action) {
  switch (action.type) {
    case ASSETS_DETAILS_ERROR: {
      return {
        ...state,
        isLoading: action.isLoading,
        assetsDetails: action.data,
      };
    }
    case ASSETS_DETAILS_START: {
      return {
        ...state,
        isLoading: action.isLoading,
      };
    }
    case ASSETS_DETAILS: {
      return {
        ...state,
        assetsDetails: action.data,
        isLoading: action.isLoading,
      };
    }
    case ASSETS_CAUSE: {
      return {
        ...state,
        assetsCause: action.data,
        isLoading: action.isLoading,
      };
    }
    case ASSETS_CAUSE_START: {
      return {
        ...state,
        isLoading: action.isLoading,
      };
    }
    case ASSETS_CAUSE_ERROR: {
      return {
        ...state,
        isLoading: action.isLoading,
      };
    }
    case SAVE_USER: {
      return {
        ...state,
        isAuth: action.isAuth,
        user: action.data,
      };
    }
    case REMOVE_USER: {
      return {
        ...state,
        isAuth: false,
        user: null,
      };
    }
    case INSPACT_AUTHORIZATION_SUCCESS: {
      return {
        ...state,
        userData: action.data,
        isLoading: false,
      };
    }
    case INSPACT_AUTHORIZATION_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case INSPACT_AUTHORIZATION_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case INSPACT_ROUTE: {
      return {
        ...state,
        isInspactRoute: action.route,
      };
    }
    case GET_ASSETS_SCHEDULE_SUCCESS: {
      return {
        ...state,
        myAssetsListSchedule: action.data,
        isLoading: false,
      };
    }
    case GET_ASSETS_SCHEDULE_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GET_ASSETS_SCHEDULE_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case SUBMIT_INSPECTION_FORM_START: {
      return {
        ...state,
        scheduleSubmitLoading: true,
      };
    }

    case SUBMIT_INSPECTION_FORM_ERROR: {
      return {
        ...state,
        scheduleSubmitLoading: false,
      };
    }
    case SUBMIT_INSPECTION_FORM_SUCCESS: {
      return {
        ...state,
        scheduleSubmitLoading: false,
      };
    }
    case USER_DATA: {
      return {
        ...state,
        userData: action.data,
      };
    }
    case SCHEDULE_LIST: {
      return {
        ...state,
        myAssetsListSchedule: action.data,
      };
    }
    case SUBMIT_CAUSES: {
      return {
        ...state,
        causesSubmit: action.data,
      };
    }
    case TOKEN_BASE_URL_START: {
      return {
        ...state,
        isLoading: action.loading
      };
    }
    case TOKEN_BASE_URL: {
      return {
        ...state,
        clientAuth: action.data,
        isLoading: action.loading
      };
    }
    case TOKEN_BASE_URL_ERROR: {
      return {
        ...state,
        clientAuth: action.data,
        isLoading: action.loading
      };
    }
    case COMPANY_LOGO: {
      return {
        ...state,
        companyLogo: action.data,
      };
    }
    case COMPANY_LOGO_ERROR: {
      return {
        ...state,
        companyLogo: action.data,
      };
    }
    case ASSETS_IMAGE: {
      return {
        ...state,
        assetImage: action.data,
      };
    }
    case ASSETS_IMAGE_ERROR: {
      return {
        ...state,
        assetImage: action.data,
      };
    }
    default:
      return state;
  }
}
