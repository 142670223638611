import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import Loader from "../components/common/Loader";
import UnAuthorized from "../components/common/UnAuthorized";
import {
  getAssetsScheduleAction,
  getDynamicUrlAction,
  inspactAuthorizationAction,
  inspectionProtectAction,
} from "../redux/action/ProductAction";
import Transitions from "./Transitions";

const InspactionProtect = ({ children }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.Product.userData?.loading);
  const userData = useSelector((state) => state.Product.userData?.data);
  const user_id = localStorage.getItem("user_id");
  const assetDetails = JSON.parse(localStorage.getItem("assetDetails"));
  useEffect(() => {
    const phone = localStorage.getItem("phone");
    const client = localStorage.getItem("clientName");
    const id = localStorage.getItem("route_id");
    if (phone && user_id === null) {
      dispatch(inspectionProtectAction(phone, assetDetails.company_id));
    } else if (phone && user_id) {
      dispatch(getDynamicUrlAction(client, id))
      dispatch(inspectionProtectAction(phone, assetDetails.company_id));
      // dispatch(getAssetsScheduleAction(user_id, assetDetails.company_id, assetDetails.id))
    }
  }, []);

  // console.log(!loading, userData != null, user_id, "userData != null && user_id?")

  return (
    <Transitions>
      {loading ? (
        <div className="h-100 d-flex flex-grow-1 justify-content-center align-items-center">
          <Loader />
        </div>
      ) : (
        <>
          {!loading && userData == null && user_id == null ? (
            <Transitions>
              <UnAuthorized />{" "}
            </Transitions>
          ) : (
            children
          )}
        </>
      )}
    </Transitions>
  );
};

export default InspactionProtect;
