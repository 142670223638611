import React, { useState, useEffect } from "react";
import { QrReader } from "react-qr-reader";
import { withRouter } from "react-router-dom";
import Transitions from "../../hoc/Transitions";
import { useTranslation } from "react-i18next";
const ScanQr = ({ history }) => {
  const { t } = useTranslation();
  const [data, setData] = useState(null);
  const [showQr, setshowQr] = useState(false);
  useEffect(() => {
    if (data) {
      window.location.replace(data);
    }
  }, [data]);
  console.log(data)
  return (
    <>
      <Transitions>
        <div className="d-flex flex-grow-1 flex-column py-5 justify-content-center align-items-center">
          {showQr ? (
            <QrReader
              className="qrReader-style"
              constraints={{
                facingMode: "environment",
              }}
              onResult={(result, error) => {
                if (!!result) {
                  setData(result?.text);
                }
                if (!!error) {
                }
              }}
            />
          ) : (
            <button
              className="scan-code _bg-primary text-white fw-600 px-5 py-2"
              onClick={() => setshowQr(true)}
            >
              {t("SCAN")}
            </button>
          )}
        </div>
      </Transitions>
    </>
  );
};

export default withRouter(ScanQr);
