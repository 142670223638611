import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Transitions from "../../hoc/Transitions";
import url from "../../helpers/url-helper";

const UnAuthorized = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const id = localStorage.getItem("route_id");
  // const id = localStorage.getItem("activeAssetsId");
  const client = localStorage.getItem('clientName');
  return (
    <Transitions>
      <div className="py-4">
        <h2 className="text-center">{t("Sorry")}!</h2>
        <h5 className="text-center">
          {t("You are not authorized person for Inspections")}
        </h5>
        <div className="text-center py-3">
          <button
            onClick={() => history.push(`/assets/${id}?client=${client}`)}
            className="default-btn  _bg-primary border-0 text-white py-2 px-4"
          >
            Go Back
          </button>
        </div>
      </div>
    </Transitions>
  );
};

export default UnAuthorized;
